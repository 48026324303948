import Link from 'next/link'
import PureskillsLogo from 'assets/pureskills-hybrid.svg'
import style from './unlogged-header.module.scss'

const UnloggedHeader = () => {
  return (
    <div className={style['unlogged-header']}>
      <Link href="/" passHref>
        <a className={style['inline-block']}>
          <PureskillsLogo style={{ height: '21px' }} />
        </a>
      </Link>
    </div>
  )
}

export default UnloggedHeader
